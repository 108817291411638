<template>
  <div class="home" ref="page" :class="{on : pageLoad}">

    <!-- banner -->
    <div class="home-banner" ref="h_banner">
      <a class="home-cover" :href="banner.link"></a>
      <van-image :src="banner.img_url" :width="375 / 37.5 + 'rem'" :height="175 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
      <div class="url">
        <van-image v-if="banner.color == 1" :src="require('../../assets/img/url_dark.png')" :width="166 / 37.5 + 'rem'" :height="12 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
        <van-image v-else-if="banner.color == 2" :src="require('../../assets/img/url_light.png')" :width="166 / 37.5 + 'rem'" :height="12 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
      </div>
    </div>

    <div class="home-content">
      <!-- search -->
      <div class="home-search" ref="h_search">
        <div class="home-search-input">
          <input class="search"  autocomplete="off" v-model="searchValue" type="text" name="search" placeholder="搜索作品或编号" @click="onHomeShowHead"/>
        </div>
        
        <div class="btn" @click="onHomeShowHead">
          <c-image iUrl="icon-search" iClass="icon" iWidth="22" iHeight="22"></c-image>
        </div>
      </div>

      <!-- btns -->
      <div class="home-btns">
        <button class="btn" @click="onPageToProducts2(1)">热门推荐</button>
        <button class="btn" @click="onPageToProducts2(2)">最新上传</button>
      </div>

      <!-- category -->
      <div class="home-category" v-if="category.length > 0">
        <div class="category" v-for="(item,index) in category" :key="index" @click="onPageToProducts(item.title)">
          <van-image :src="item.img_url" :width="170 / 37.5 + 'rem'" :height="170 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
          <div class="name">{{item.title}}</div>
        </div>
      </div>

      <!-- user -->
      <div class="home-user">
        <div class="title">设计师<i></i></div>
        <div class="list" v-if="designers.length > 0">
          <div class="user" v-for="(item , index) in designers" :key="index" @click="onPageToMe(item.follow_user_id)">
            <div class="avatar">
              <div class="home-cover"></div>
              <van-image :src="item.head_img" :width="80 / 37.5 + 'rem'" :height="80 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
            </div>
            <div class="name">{{item.nickname}}</div>
            <div class="bottom">
              <div class="num"><i>{{item.nums}}</i>粉丝</div>
              <div class="btn">
                <c-image iUrl="icon-add" iClass="icon" iWidth="7" iHeight="7" ></c-image>
                关注
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="home-footer">
      <div class="home-cover">
        <div class="logo">
          <div class="home-cover"></div>
          <van-image :src="require('../../assets/img/logo.png')" :width="133 / 37.5 + 'rem'" :height="21 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
        </div>
        <p><i>邀请好友</i><i>分享作品</i><i>轻松赚钱</i></p>
        <div class="btn" @click="onPageToInvitation">我的邀请</div>
        <div class="copy_right">Copyright © {{year}} 享设计 Design006.com</div>
      </div>
      <van-image :src="require('../../assets/img/footer.png')" :width="375 / 37.5 + 'rem'" :height="217 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
    </div>

    <c-login-coupon ref="cLoginCoupon"></c-login-coupon>
  </div>
</template>

<script>
import { Image, Overlay } from "vant";
import CImage from "../../components/CImage";
import { mapState, mapActions } from "vuex";
import { dialogAlert2 } from "../../lib/util";
import CLoginCoupon from '../../components/CLoginCoupon';
import { loginCoupon } from '../../lib/mix/login-coupon';
import { loginThird } from "../../lib/mix/login-third";
export default {
    components: {
        [Image.name]: Image,
        [Overlay.name]: Overlay,
        [CImage.name]: CImage,
        [CLoginCoupon.name]: CLoginCoupon
    },
    mixins: [loginCoupon, loginThird],
    data() {
        return {
            pageLoad: false,
            searchValue: "",
            year:(new Date).getFullYear(),
        };
    },
    computed: {
        ...mapState({
            banner: (state) => state.home.indexData.homepage_banner,
            category: (state) => state.home.indexData.theme_list,
            designers: (state) => state.home.indexData.designer_list,
            hSearchTop: (state) => state.hSearchTop, //大搜索框底部边框到页面顶部的距离
            userData: (state) => state.me.userData,
        }),
    },
    methods: {
        ...mapActions({
            requestIndex: "home/requestIndex",
            changeHomeSearch: "changeHomeSearch",
            requestGetAuth: "user/requestGetAuth",
            requestInfo: "me/requestInfo"
        }),
        onHomeShowHead() {
          // 创建home2 只是为了首页点击大搜索框 展示搜索页面
          let _inviteCode = this.$cookies.get('invite_code')
          this.$router.push({
            name: "home2",
            query: {inviteCode: _inviteCode}
          });
        },
        onPageToProducts(val) {
            if (val) {
              this.saveHistory(val);
                this.$router.push({
                    name: "products",
                    params: { keywords: val },
                    query: { keywords: val }
                });
            } else {
                dialogAlert2("请输入搜索关键词");
            }
        },
        onPageToProducts2(val) {
            this.$router.push({
                name: "products",
                params: { keywords: "more_works", option: val },
                query: { keywords: "more_works", option: val }
            });
        },
        // 存储关键词到cookie
        saveHistory(keywords) {
          // 处理keywords， 转大写/去2边空格/判断特殊词
          keywords = keywords.trim().toUpperCase();

          if(keywords) {
              var searchHistory = this.$cookies.get('searchHistory');
              searchHistory = searchHistory ? JSON.parse(searchHistory) : [];
              // 如果关键词已存在，先移除旧的关键词
              var index = searchHistory.indexOf(keywords);
              if (index !== -1) {
                  searchHistory.splice(index, 1);
              }
              searchHistory.unshift(keywords);
              // 限制搜索历史长度，可以根据需要调整
              if (searchHistory.length > 10) {
                  searchHistory.pop(); // 移除最后一个搜索词
              }
              this.$cookies.set('searchHistory', JSON.stringify(searchHistory),86400*30);
          }
        },
        onPageToMe(id) {
            this.$router.push({
                name: "homepage",
                params: { id: id, enterType: "click" },
                query: { tab: 0 }
            });
        },
        onPageToInvitation() {
            this.$router.push({
                name: "invitation",
                params: {},
            });
        },
        getSearchTop() {
            let _searchH = this.$refs.h_search.offsetHeight;
            let _searchTop = this.$refs.h_search.offsetTop;
            let _bannerH = this.$refs.h_banner.offsetHeight;
            let _scrollH = _searchH + _searchTop + _bannerH;
            this.changeHomeSearch(_scrollH);
        },
    },
    created() {
        //不管用户是否登录，有inviteCode参数都保存到cookies （未登录用户注册填写邀请人的邀请码）
        if (this.$route.query.inviteCode) {
            this.$cookies.set('share_invite_code', this.$route.query.inviteCode, 60 * 60 * 24 * 7)
        }

        //如果用户处于登录状态，url末尾加载分享邀请码参数
        if (this.$cookies.isKey('user_id')) {
            let _inviteCode = this.$cookies.get('invite_code')
            if (this.$route.query.new_reg) this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode, new_reg: this.$route.query.new_reg } })
            else this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode } })
        }

        this.pageLoad = false;
        this.requestIndex().then(() => {
            this.pageLoad = true;
        });
    },
    mounted() {
        this.getSearchTop();
    },
    destroyed() { },
};
</script>

<style>
.home{opacity: 0;}
.home.on{opacity: 1;}
.home-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.home-banner{width:100%;height:175px;overflow:hidden; background: #ededed;}
.home-banner .url{position:absolute;left:12px;bottom:36px;z-index:1;width:116px; height: 12px;}
.home-content{width:100%;padding:0 12px}
.home-search{width:350px;height:54px;z-index:100;position:absolute;display:flex;justify-content:space-between;overflow:hidden;align-items:center;background:#fff;border-radius:4px;position:absolute;left:12px;top:-22px}
.home-search .btn{width:54px;height:54px;display:flex;justify-content:center;align-items:center}
.home-search-input{flex:1; height: 54px; padding:15px 0;}
.home-search .search{width:100%;height:24px;line-height:24px;font-size:16px;color:#666666;text-indent:20px;border:none;margin:0;padding:0; vertical-align: top;}
.home-search .search::-webkit-input-placeholder{font-size:16px;color:#666666}
.home-btns{display:flex;justify-content:space-between;align-items:center}
.home-btns .btn{width:170px;height:52px;border:none;background:#f85659;margin-top:51px;line-height:52px;text-align:center;color:#fff;font-size:14px;border-radius:4px}
.home-category{display:flex;flex-wrap:wrap;justify-content:space-between;margin-top:32px;overflow:hidden}
.home-category .category{width:170px;height:170px;border-radius:4px;margin-bottom:12px; overflow: hidden; background: #ededed;}
.home-category .name{width:100%;height:100%;position:absolute;left:0;top:0;z-index:1;display:flex;justify-content:center;align-items:center;font-size:16px;color:#ffffff;background:rgba(0,0,0,0.3)}
.home-user{margin-top:32px}
.home-user .title{text-align:center;color:#2e2e30;font-size:20px}
.home-user .title i{display:block;width:32px;height:2px;position:absolute;left:0;right:0;bottom:-9px;margin:auto;background:#f85659}
.home-user .list{display:flex;flex-wrap:wrap;justify-content:space-between;margin-top:18px}
.home-user .user{width:170px;border-radius:4px;overflow:hidden;background:#fff;margin-top:12px;padding:0 12px 12px}
.home-user .avatar{width:80px;height:80px;margin:26px auto 0; border-radius: 85px; overflow: hidden; background: #ededed;}
.home-user .name{width:146px;margin-top:26px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;font-size:14px;color:#2e2e30}
.home-user .bottom{display:flex;justify-content:space-between;align-items:center;margin-top:4px}
.home-user .num{color:#b3b3b3;font-size:12px}
.home-user .num i{font-style:normal;margin-right:4px}
.home-user .btn{color:#b3b3b3;font-size:12px;display:flex;justify-content:flex-end;align-items:center}
.home-user .btn .icon{margin-right:4px}
.home-footer{width:100%;height:217px;margin-top:32px}
.home-footer .logo{width:133px;margin:36px auto 0;height:21px}
.home-footer .logo .van-image{vertical-align:top}
.home-footer p{font-size:16px;color:#ffffff;text-align:center;letter-spacing:1px;margin:32px 0 0}
.home-footer p i{font-style:normal;margin:0 6px}
.home-footer .btn{width:122px;height:36px;margin:12px auto 0;border:1px solid #fff;letter-spacing:1px;border-radius:27px;text-align:center;line-height:36px;font-size:14px;color:#ffffff;}
.home-footer .copy_right{text-align:center;font-size:10px;color:#666666;transform:scale(0.9);margin-top:32px}
.home-dialog-coupon .btn{margin:24px auto 0}
.home-dialog-coupon .date{position: absolute; width:100%; left: 0; top: 227px; z-index: 1; font-size: 12px; color: #b3b3b3; text-align: center;}
.home-dialog-coupon .date i{font-style: normal; color: #F85659; margin-left: 10px;}
</style>